<template>
  <div>
    <div
      v-if="mode != 'Paquete' && mode != 'PaquetePeriodicidad'"
      class="container"
    >
      <el-form :inline="true">
        <el-form-item :label="$tc('payment')">
          <select-service-recurrence-signup
            v-model="form.model.company.companyServiceFrequencyBilling"
            :label-all="false"
            @change="$emit('change-recurrence', $event)"
          >
            <span slot="prefix"> Pago: </span>
          </select-service-recurrence-signup>
        </el-form-item>
      </el-form>
    </div>
    <div v-cus-loading="form.loading">
      <div class="container">
        <div class="container-title">
          <el-select
            v-if="mode == 'Paquete' && serviceId == 2"
            v-model="filterValue"
            style="max-width: 600px"
          >
            <el-option
              v-if="filterValue != 'DE' && filterValue != 'DE-A'"
              label="Paquetes de facturación electrónica"
              value="P"
            >
            </el-option>
            <el-option
              v-if="filterValue != 'DE' && filterValue != 'DE-A'"
              label="Paquetes de documentos electronicos todo incluido"
              value="TD"
            >
            </el-option>
            <el-option
              v-if="filterValue != 'DE' && filterValue != 'DE-A'"
              label="Paquetes de documentos electrónicos todo incluido con recepción automática"
              value="TD-A"
            >
            </el-option>
            <el-option
              v-if="filterValue == 'DE'"
              label="Paquetes de documentos electrónicos"
              value="DE"
            >
            </el-option>
            <el-option
              v-if="filterValue == 'DE-A'"
              label="Paquetes de documentos electrónicos con recepción automática"
              value="DE-A"
            >
            </el-option>
          </el-select>

          <el-alert
            v-if="mode == 'Paquete' && serviceId == 2"
            :closable="false"
            type="info"
            show-icon
            style="margin-top: 8px; max-width: 600px"
          >
            <div v-if="filterValue == 'P'">
              Documentos electrónicos incluidos:
              <ul class="check-list">
                <li>Facturas electrónicas de venta</li>
                <li>Notas crédito electrónicas</li>
                <li>Notas débito electrónicas</li>
              </ul>
            </div>
            <div v-else-if="filterValue == 'TD'">
              Documentos electrónicos incluidos:
              <ul class="check-list">
                <li>Facturas electrónicas de venta</li>
                <li>Notas crédito electrónicas</li>
                <li>Notas débito electrónicas</li>
                <li>Nómina electrónica</li>
                <li>Ajustes de nómina electrónica</li>
                <li>Documentos soporte</li>
                <li>Ajustes de documento soporte</li>
                <li>Recepción de facturas electrónicas (eventos)</li>
              </ul>
            </div>
            <div v-else-if="filterValue == 'TD-A'">
              <span
class="text-primary"
style="margin: 0px"
>La recepción tiene un buzón virtual que recibe tus facturas en
                forma automática</span>
              <br />
              Documentos electrónicos incluidos:
              <ul class="check-list">
                <li>Facturas electrónicas de venta</li>
                <li>Notas crédito electrónicas</li>
                <li>Notas débito electrónicas</li>
                <li>Nómina electrónica</li>
                <li>Ajustes de nómina electrónica</li>
                <li>Documentos soporte</li>
                <li>Ajustes de documento soporte</li>
                <li>Recepción de facturas electrónicas (eventos)</li>
              </ul>
            </div>
            <div v-else-if="filterValue == 'DE'">
              Documentos electrónicos incluidos:
              <ul class="check-list">
                <li>Facturas electrónicas de venta</li>
                <li>Notas crédito electrónicas</li>
                <li>Notas débito electrónicas</li>
                <li>Nómina electrónica</li>
                <li>Ajustes de nómina electrónica</li>
                <li>Documentos soporte</li>
                <li>Ajustes de documento soporte</li>
                <li>Recepción de facturas electrónicas (eventos)*</li>
              </ul>
              <br />
              <span
class="text-primary"
style="margin: 0px"
>La recepción de facturas a crédito se cuenta cada evento
                emitido como un documento.</span>
            </div>
            <div v-else-if="filterValue == 'DE-A'">
              <span
class="text-primary"
style="margin: 0px"
>La recepción tiene un buzón virtual que recibe tus facturas en
                forma automática</span>
              <br />
              Documentos electrónicos incluidos:
              <ul class="check-list">
                <li>Facturas electrónicas de venta</li>
                <li>Notas crédito electrónicas</li>
                <li>Notas débito electrónicas</li>
                <li>Nómina electrónica</li>
                <li>Ajustes de nómina electrónica</li>
                <li>Documentos soporte</li>
                <li>Ajustes de documento soporte</li>
                <li>Recepción de facturas electrónicas (eventos)*</li>
              </ul>
              <br />
              <span
class="text-primary"
style="margin: 0px"
>La recepción de facturas a crédito se cuenta cada evento
                emitido como un documento.</span>
            </div>
          </el-alert>

          <el-carousel
            arrow="always"
            :autoplay="false"
            :initial-index="indexCurrent"
            type="card"
            :height="mode == 'Paquete' ? '450px' : '700px'"
            style="margin-top: 16px; margin-bottom: 16px; min-width: 600px"
          >
            <el-carousel-item v-for="(item, index) in plans" :key="index">
              <div class="card col col-select">
                <div class="card-header">
                  <span
                    v-if="form.model.company.planId === item.id"
                    class="title text-success"
                    >Tu plan actual</span>
                  <h2 v-if="mode !== 'Paquete'" class="title">
                    {{ item.planName }}
                  </h2>
                  <p class="price">
                    {{ getPrice(item) | formatToCurrency }}
                  </p>
                  <p v-if="mode === 'Paquete'" class="subtitle">
                    Vigencia 1 año
                  </p>
                  <div class="line"></div>
                </div>
                <table class="features" width="100%">
                  <tbody>
                    <tr
                      v-for="(itemFeature, indexFeature) in getFeatures(
                        item.features,
                      )"
                      :key="indexFeature"
                    >
                      <td align="center">
                        <span
                          v-if="
                            itemFeature.featureType === 'access' &&
                            itemFeature.featureValue === 'true'
                          "
                          class="tag primary"
                        >
                          <i class="fad fa-check fa-lg"></i>
                        </span>
                        <span
                          v-if="
                            itemFeature.featureType !== 'access' &&
                            itemFeature.featureValue > 0
                          "
                          class="tag primary"
                        >
                          {{ itemFeature.featureValue }}
                        </span>
                        <span
                          v-if="
                            itemFeature.featureType !== 'access' &&
                            itemFeature.featureValue < 0
                          "
                          class="tag primary"
                        >
                          {{ $tc('unlimited') }}
                        </span>
                      </td>
                      <td>
                        {{ itemFeature.featureName }}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <el-button
                  size="mini"
                  class="btn btn-select"
                  style="margin-bottom: 8px"
                  @click="onSelect(item)"
                >
                  Seleccionar
                </el-button>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
    </div>

    <!-- #region  payment  -->
    <el-dialog
      :close-on-click-modal="false"
      :loading="dialog.payment.loading"
      :visible.sync="dialog.payment.visible"
      width="95%"
      append-to-body
      @opened="onOpenedPayment"
    >
      <payment
        v-if="dialog.payment.visible"
        ref="payment"
        :detail-type="$route.params.detailType || 'Apertura'"
        :is-embedded="$route.params.isEmbedded === 'true'"
        :model="form.model"
        source="Plans"
      ></payment>
    </el-dialog>
    <!-- #endregion  -->
    <!-- #region  payments  -->
    <el-dialog
      :loading="dialog.payments.loading"
      :visible.sync="dialog.payments.visible"
      width="98%"
      append-to-body
    >
      <payments
        v-if="dialog.payments.visible"
        :voucher-id="form.model.voucher.id"
      ></payments>
    </el-dialog>
    <!-- #endregion  -->
    <!-- #region  personalize  -->
    <el-dialog
      :visible.sync="dialog.personalize.visible"
      width="98%"
      append-to-body
      top="9vh"
    >
      <personalize
        v-if="dialog.personalize.visible"
        :frequency-billing="form.model.company.companyServiceFrequencyBilling"
        :detail-type="$route.params.detailType || 'Apertura'"
        :plan="form.model.plan"
        :voucher="form.model.voucher"
        @next="onPersonalizeComplete"
      ></personalize>
    </el-dialog>
    <!-- #endregion  -->
  </div>
</template>

<script>

/* app
-------------------------------------------------- */
import { buildQueryString } from '@/helpers'
import { form } from '@/views/common'
import dialog, { showDialog } from '@/views/common/dialog'
import model from '@/views/placeToPay/model'
import { setTimeout } from 'timers'

/* End of app
-------------------------------------------------- */

export default {
  name: 'HubPlans',
  components: {
    payment: _ => import('@/views/placeToPay/payment'),
    payments: _ => import('@/views/placeToPay/payments'),
    personalize: _ => import('@/views/plan/personalize')
  },
  data() {
    return {
      dialog: {
        askedQuestionsElectronicPayments: {
          ...dialog()
        },
        payment: {
          ...dialog()
        },
        payments: {
          ...dialog()
        },
        personalize: {
          ...dialog()
        }
      },
      form: form({ model }),
      list: {
        plans: []
      },
      indexCurrent: 0,
      filterValue: ''
    }
  },
  computed: {
    companyId: _this => _this.$route.params.companyId,
    companyServiceFrequencyBilling: _this => {
      let frecuency = ''
      switch (_this.periodicidad) {
        case 'Anual':
          frecuency = 'Yearly'
          break
        case 'Mensual':
          frecuency = 'Monthly'
          break
        case 'Trimestral':
          frecuency = 'Quarterly'
          break
        case 'Semestral':
          frecuency = 'Semiannual'
          break
        default:
          frecuency = _this.form.model.company.companyServiceFrequencyBilling
      }

      return frecuency
    },
    crmParams: _this => {
      if (_this.$route.query.product) {
        return _this.$route.query.product.split('-')
      }

      return []
    },
    service: _this => {
      if (_this.crmParams.length > 0) {
        return _this.crmParams[0].trim().toLowerCase()
      }
      if (_this.$route.params.service) {
        return _this.$route.params.service
      }

      return _this.$route.params.serviceId
    },
    serviceId: _this => {
      if (_this.service === 'fe') {
        return 1
      }
      if (_this.service === 'aliaddo') {
        return 2
      }
      if (_this.service === 'isv') {
        return 7
      }
      if (_this.service === 'aliaddopos') {
        return 8
      }
      if (_this.service === 'aliaddonominafull') {
        return 10
      }
      if (_this.service === 'aliaddonominalite') {
        return 11
      }
      if (_this.service === 'aliaddonominaisv') {
        return 12
      }
    },
    planName: _this => _this.$route.params.planName || '',
    mode: _this => {
      if (_this.crmParams.length > 1) {
        return _this.crmParams[1].trim()
      }

      return ''
    },
    periodicidad: _this => {
      if (_this.mode === 'Paquete') {
        return 'Anual'
      }
      if (_this.mode === 'Demanda') {
        return 'Mensual'
      }
      if (_this.crmParams.length > 2) {
        return _this.crmParams[2].trim()
      }

      return ''
    },
    price: _this => {
      if (_this.crmParams.length > 3) {
        return _this.crmParams[3].trim()
      }

      return ''
    },
    plans: _this => {
      let data = _this.list.plans.filter(item => _this.getPrice(item) > 0)
      if (_this.serviceId === 2) {
        data = _this.list.plans.filter(
          item => item.planName.includes(_this.filterValue) &&
            _this.getPrice(item) > 0
        )
        if (_this.filterValue === 'TD') {
          data = _this.list.plans.filter(
            item => item.planName.includes('TD-') &&
              !item.planName.includes('A') &&
              _this.getPrice(item) > 0
          )
        } else if (_this.filterValue === 'TD-A') {
          data = _this.list.plans.filter(
            item => item.planName.includes('TD-') &&
              item.planName.includes('A') &&
              _this.getPrice(item) > 0
          )
        } else if (_this.filterValue === 'DE') {
          data = _this.list.plans.filter(
            item => item.planName.includes('DE') &&
              !item.planName.includes('A') &&
              _this.getPrice(item) > 0
          )
        } else if (_this.filterValue === 'DE-A') {
          data = _this.list.plans.filter(
            item => item.planName.includes('DE') &&
              item.planName.includes('A') &&
              _this.getPrice(item) > 0
          )
        }
      } else if (_this.serviceId === 7) {
        data = _this.list.plans.filter(
          item => item.planName.includes(_this.filterValue) &&
            _this.getPrice(item) > 0
        )
      }

      _this.indexCurrent = data.findIndex(
        item => _this.form.model.company.planId === item.id
      )

      return data
    }
  },
  mounted() {
    this.getCompany()
  },
  methods: {
    showDialog,
    onOpenedPayment() {
      const _this = this
      setTimeout(_ => _this.$refs.payment.fillForm(_this.form.model), 1000)
    },
    onShowPayment() {
      this.showDialog('payment')
    },
    onShowPersonalize() {
      this.showDialog('personalize')
    },
    onShowPayments(voucher) {
      const _this = this
      _this.form.model.voucher = voucher
      _this.showDialog('payments')
    },
    onPersonalizeComplete({ company, voucher }) {
      const _this = this
      _this.form.model = {
        ..._this.form.model,
        ...{
          company: {
            ..._this.form.model.company,
            ...company
          },
          voucher: {
            ..._this.form.model.voucher,
            ...voucher
          }
        }
      }

      _this.onShowPayment()
    },
    onSelect(plan) {
      const _this = this
      _this.form.model = {
        ..._this.form.model,
        plan
      }
      _this.onShowPersonalize()
    },
    getFeatures(features) {
      if (!features) {
        features = []
      }

      return features
        .filter(item => !(+item.featureValue === 0) && item.featureIsVisible)
        .filter(
          item => !(
            (item.featureType === 'access' &&
                item.featureValue === 'false') ||
              +item.featureValue === 0
          )
        )
        .sort((a, b) => {
          const valueA =
            a.featureValue === 'true'
              ? 1
              : a.featureValue === 'false'
                ? -1
                : +a.featureValue < 0
                  ? 1000000000
                  : +a.featureValue
          const valueB =
            b.featureValue === 'false'
              ? -1
              : b.featureValue === 'true'
                ? 1
                : +b.featureValue < 0
                  ? 1000000000
                  : +b.featureValue

          return valueB - valueA
        })
    },
    getCompany() {
      const _this = this
      if (_this.companyId) {
        _this.form.loading = true

        return _this.$http
          .get(
            `companies/findByIdByService/${_this.companyId}/${_this.serviceId}`
          )
          .then(result => (_this.form.model.company = result.data))
          .finally(_ => {
            _this.form.loading = false
            _this.getPlan()
          })
      }
      _this.form.model.company.companyServiceFrequencyBilling =
        _this.companyServiceFrequencyBilling
    },
    getPlansPriceGreatherThanCero() {
      const _this = this

      /* const planActual = _this.list.plans.find(item => _this.form.model.company.planId === item.id) */

      const planes = _this.list.plans.filter(
        item => ((_this.form.model.company.companyServiceFrequencyBilling ===
            'Yearly' &&
            +item.planPriceYearly > 0) ||
            (_this.form.model.company.companyServiceFrequencyBilling ===
              'Monthly' &&
              +item.planPriceMonthly > 0) ||
            (_this.form.model.company.companyServiceFrequencyBilling ===
              'Quarterly' &&
              +item.planPriceQuarterly > 0) ||
            (_this.form.model.company.companyServiceFrequencyBilling ===
              'Semiannual' &&
              +item.planPriceSemiannual > 0)) &&
          _this.form.model.company.planId !== item.id
      )

      return planes

      /* .splice(0, 0, planActual) */
    },
    getPlan() {
      const _this = this

      const query = {
        serviceId: _this.serviceId,
        planOnSite: true,
        planEnabled: true,
        limit: 100,
        planMode: _this.mode
      }

      _this.loading = true
      _this.$http
        .get(`plans/search?${buildQueryString(query)}`)
        .then(response => {
          if (
            response.data &&
            response.data.data &&
            response.data.data.length > 0
          ) {
            _this.list.plans = response.data.data
          } else if (
            response.data &&
            response.data.data &&
            response.data.data.length < 1
          ) {
            _this.list.plans = []
          } else {
            _this.list.plans = response.data
          }

          if (_this.list.plans.length < 1) {
            this.$message({
              showClose: true,
              message: _this.$tc('messageNoFoundPlan'),
              type: 'error'
            })
          }

          if (_this.list.plans.length === 1) {
            _this.onSelect(_this.list.plans[0])
          } else if (_this.$route.params.detailType === 'PaquetePeriodicidad') {
            _this.onSelect(
              _this.list.plans.filter(
                item => item.planName === _this.$route.params.planName
              )[0]
            )
          }

          // _this.list.plans = _this.list.plans.filter(item => { return _this.form.model.company.planId !== item.id })
          if (_this.mode === 'Paquete') {
            const planCurrent = _this.list.plans.find(
              item => _this.form.model.company.planId === item.id
            )
            if (planCurrent) {
              if (_this.serviceId === 2) {
                _this.filterValue = planCurrent.planName.includes('DE')
                  ? planCurrent.planName.includes('A')
                    ? 'DE-A'
                    : 'DE'
                  : planCurrent.planName.includes('TD') &&
                    planCurrent.planName.includes('A')
                    ? 'TD-A'
                    : planCurrent.planName.includes('TD')
                      ? 'TD'
                      : 'P'
              } else if (_this.serviceId === 7) {
                _this.filterValue = planCurrent.planName.includes('IP')
                  ? 'IP'
                  : planCurrent.planName.includes('LP')
                    ? 'LP'
                    : ''
              }
            }
          }
        })
        .finally(_ => (_this.loading = false))
    },
    getPrice(plan) {
      const _this = this
      let price = 0
      switch (_this.form.model.company.companyServiceFrequencyBilling) {
        case 'Yearly':
          price =
            _this.price === 'new'
              ? plan.planPriceYearlyNew
              : plan.planPriceYearly
          break
        case 'Monthly':
          price =
            _this.price === 'new'
              ? plan.planPriceMonthlyNew
              : plan.planPriceMonthly
          break
        case 'Quarterly':
          price =
            _this.price === 'new'
              ? plan.planPriceQuaterlyNew
              : plan.planPriceQuaterly
          break
        case 'Semiannual':
          price =
            _this.price === 'new'
              ? plan.planPriceSemiannualNew
              : plan.planPriceSemiannual
          break
        default:
          price = 0
      }

      return price
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/transactions/select-plan.scss';
</style>
